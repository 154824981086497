import Head from 'next/head';
import React from 'react';

import { useIsAppleMobile } from '@sravni/react-utils';

/** Uses for sravni IOS app banner, instruction https://support.appsflyer.com/hc/en-us/articles/115000171743-Attributing-iOS-Safari-App-Banners */
export const AppAnalyticsPixel = () => {
  const isAppleMobile = useIsAppleMobile();

  if (!isAppleMobile) return null;

  return (
    <>
      <Head>
        <meta name="apple-itunes-app" content="app-id=1449578663" />;
      </Head>
      <img
        src="https://www.sravni.ru/analytics-links/v1/redirects/mobileapps/?placement=sravni_main_safari_app_banners&is_retargeting=true"
        height="1"
        width="1"
        // @ts-ignore
        border="0"
        alt=""
      />
    </>
  );
};

import type { GetBannersParams, TBannersList } from '../interfaces/advertising';
import { DecoratedBannersList } from '../interfaces/advertising';
import { decorateBanners } from '../utils/decorateBanners';

import GatewayClient from './clients/GatewayClient';

export const loadBanners = async ({ params }: { params: GetBannersParams }): Promise<DecoratedBannersList> => {
  const { data } = await GatewayClient.get<TBannersList>('/v1/advertising/banners', { params });

  return decorateBanners(data);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DepositsTop, DepositsTopPeriods } from '../../interfaces/deposits';
import { generateDepositHref } from '../../utils/deposits';

const initialState: DepositsTop = [
  { period: 3, rate: '', depositHref: generateDepositHref(DepositsTopPeriods.THREE_MONTHS) },
  { period: 6, rate: '', depositHref: generateDepositHref(DepositsTopPeriods.SIX_MONTHS) },
  { period: 12, rate: '', depositHref: generateDepositHref(DepositsTopPeriods.TWELVE_MONTH) },
  { period: 36, rate: '', depositHref: generateDepositHref(DepositsTopPeriods.THREE_YEARS) },
];

const depositsTopSlice = createSlice({
  name: 'depositsTop',
  initialState,
  reducers: {
    setDepositsTop: (_, action: PayloadAction<DepositsTop>) => {
      return action.payload;
    },
  },
});

const { actions, reducer } = depositsTopSlice;

export const { setDepositsTop } = actions;

export default reducer;

import { sendHeaderEvent } from 'helpers/analyticsEvents';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef } from 'react';

import { DesktopHeader, MobileHeader } from '@sravni/react-header';
import { GlobalSearch } from '@sravni/react-search/lib/GlobalSearch';
import { useIsMobile } from '@sravni/react-utils';

import { useCenters, useCurrentLocation } from '../../hooks/locations';
import { useSiteSettings } from '../../hooks/sitesettings';
import { useUser } from '../../hooks/users';

import styles from './header.module.scss';

export const Header = () => {
  const { asPath } = useRouter();
  const settings = useSiteSettings();
  const isMobile = useIsMobile();
  const user = useUser();
  const centers = useCenters();
  const currentLocation = useCurrentLocation();
  const headerRef = useRef<HTMLDivElement>();
  const domains = getConfig().publicRuntimeConfig.headerDomains;
  const searchUrl = getConfig().publicRuntimeConfig.publicUrl;

  const ComponentToRender = isMobile ? MobileHeader : DesktopHeader;

  const formatPath = useCallback(
    (_location) => {
      let url = asPath;

      if (url.match(currentLocation.alias)) {
        url = url.replace(`${currentLocation.alias}/`, '');
      }

      return url;
    },
    [asPath, currentLocation.alias],
  );

  useEffect(() => {
    if (headerRef.current) {
      const logo = headerRef.current.querySelector('[data-element="logo-link"]');
      if (logo) {
        const onLogoClick = () => {
          sendHeaderEvent();
        };
        logo.addEventListener('click', onLogoClick);

        return () => logo.removeEventListener('click', onLogoClick);
      }
    }
  }, []);

  return (
    <ComponentToRender
      enableTheme
      ref={headerRef}
      className={styles.whiteBackground}
      serviceURL={asPath}
      regions={centers}
      currentRegion={currentLocation}
      formatPath={formatPath}
      settings={settings.header}
      user={user}
      domains={domains}
      search={<GlobalSearch searchServiceHost={searchUrl} asPath={asPath} />}
    />
  );
};

import { useSelector } from 'react-redux';

import { ILocation } from '../store/reducers/locations';
import { getCenters, getCurrentLocation } from '../store/selectors/locations';

export function useCenters(): ILocation[] {
  return useSelector(getCenters);
}
export function useCurrentLocation(): ILocation {
  return useSelector(getCurrentLocation);
}

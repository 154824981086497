import { AxiosResponse } from 'axios';
import getConfig from 'next/config';

import { ILocation } from '../store/reducers/locations';
import client from '../utils/client';

const apiGatewayUrl = getConfig().publicRuntimeConfig.apiGatewayUrl;

export const findRegions = async (): Promise<AxiosResponse<ILocation[]>> => {
  const { data } = await client.get(`${apiGatewayUrl}/location/v1.0/locations/regions/`);

  return data;
};

export const findRegionalCenters = async (): Promise<AxiosResponse<ILocation[]>> => {
  const { data } = await client.get(`${apiGatewayUrl}/location/v1.0/locations/regional-centers/`);

  return data;
};

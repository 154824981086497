import { MONETIZATION_TYPES } from '../constants/advertising';
import { IItem } from '../interfaces/advertising';
import { itemLabelFactory, makeAdGEvent, makeMainGEvent, pushEvent } from '../utils/analytics';

const EVENT_CATEGORY = 'Кредиты';

interface IAnalyticsItem {
  advertising?: IItem;
  name: string;
  organization: {
    name: string;
  };
}

export const sendHeaderEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Прочее',
      eventAction: 'Лого|Сравни',
      eventLabel: 'www.sravni.ru/',
    }),
  );
};

/** AD G_EVENTS **/
export const sendSortEvent = (value) => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Сортировка',
      eventLabel: value,
    }),
  );
};

export const sendFilterEvent = (label, values) => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: ['Фильтр', label],
      eventLabel: values,
    }),
  );
};

export const sendPaginationEvent = () => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Показать еще',
    }),
  );
};

export const sendMenuClickEvent = ({ category, label }: { category?: string; label?: string }) => {
  pushEvent(
    makeAdGEvent({
      eventAction: 'Переход из плиток',
      eventCategory: category,
      eventLabel: label,
    }),
  );
};

/** MAIN G_EVENTS **/
export const sendAdvancedBlockOpenEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Подробнее',
      eventLabel: itemLabelFactory(item),
    }),
  );
};

export const sendGroupOpenEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Еще предложения',
      eventLabel: itemLabelFactory(item),
    }),
  );
};

export const sendCardOpenEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Переход в карточку',
      eventLabel: itemLabelFactory(item),
    }),
  );
};

export const sendPaidClickEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Платный клик',
      eventLabel: itemLabelFactory(item),
    }),
  );
};

export const sendGoToRequestEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Переход к оформлению заявки',
      eventLabel: itemLabelFactory(item),
    }),
  );
};

const REQUESTS_MONETIZATIONS = new Set([
  MONETIZATION_TYPES.REQUEST_LONG,
  MONETIZATION_TYPES.REQUEST_SHORT,
  MONETIZATION_TYPES.APPROVAL,
]);

export const sendAdvertisingButtonClickEvent = (item: IAnalyticsItem) => {
  const { advertising } = item;

  if (advertising) {
    const {
      monetization: { kind },
    } = advertising;

    // todo: maybe this logic is not valid, talk with manager
    if (REQUESTS_MONETIZATIONS.has(kind)) {
      sendGoToRequestEvent(item);
    } else {
      sendPaidClickEvent(item);
    }
  }
};

const PAGE_VIEW_GA_STATE: { title: string; path: string } = {
  title: '',
  path: '',
};

export const checkAndSendPageViewGAEvent = (title, path) => {
  if (title !== PAGE_VIEW_GA_STATE.title && path !== PAGE_VIEW_GA_STATE.path) {
    pushEvent({
      event: 'pageViewGA',
      title,
      path,
    });

    Object.assign(PAGE_VIEW_GA_STATE, {
      title,
      path,
    });
  }
};

export enum DepositsTopPeriods {
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
  TWELVE_MONTH = 'twelveMonths',
  THREE_YEARS = 'threeYears',
}

export type DepositsTopRaw = {
  rate: number;
  deposits: any[];
};

export type DepositTop = {
  period: number;
  rate: string;
  depositHref: string;
};

export type DepositsTop = Array<DepositTop>;

import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { IGlobalState } from '../store';

interface IProps extends React.ComponentProps<any> {
  store: Store<IGlobalState>;
}

const StoreProvider = ({ store, children }: IProps) => <Provider store={store}>{children}</Provider>;

export default StoreProvider;

import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { GetBannersParams } from '../../interfaces/advertising';
import { DecoratedBannersList } from '../../interfaces/advertising';
import { loadBanners } from '../../services/advertising';

export interface IState {
  banners: DecoratedBannersList;
  loading: boolean;
  loadingError: SerializedError;
}

const initialState: IState = {
  banners: null,
  loading: false,
  loadingError: null,
};

const name = 'advertising';

interface IFetchParams {
  params: GetBannersParams;
  paramsForDecorating?: {
    [key: string]: string | string[];
  }[];
}

export const fetchBanners = createAsyncThunk<DecoratedBannersList, IFetchParams>(
  `${name}/fetchBanners`,
  async ({ params }) => {
    return await loadBanners({ params });
  },
);

const advertisingSLice = createSlice({
  name,
  initialState,
  reducers: {
    setBanners: (state, { payload }: PayloadAction<DecoratedBannersList>) => {
      state.banners = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.fulfilled, (state, { payload }) => {
        state.banners = payload;
        state.loading = false;
        state.loadingError = null;
      })
      .addCase(fetchBanners.rejected, (state, { error }) => {
        state.loading = false;
        state.loadingError = error;
      })
      .addCase(fetchBanners.pending, (state) => {
        state.loading = true;
      });
  },
});

const { actions, reducer } = advertisingSLice;

export const { setBanners } = actions;

export default reducer;

export const MONETIZATION_TYPES = {
  REFERRAL: 'referral',
  REQUEST_SHORT: 'request-short',
  REQUEST_LONG: 'request-long',
  COMMISSION: 'commission',
  PROMO_CODE: 'promo-code',
  APPROVAL: 'approval',
};

export const ADV_SUB_KEY = 'adv_sub';
export const ADV_SOURCE = 'source';

export const AVAILABLE_ADVERTISING_PIXELS_KEYS = [ADV_SUB_KEY, ADV_SOURCE];

export const AGGREGATORS = {
  MOEX: 'moex',
  SRAVNI: 'sravni',
};

import { useSelector } from 'react-redux';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGlobalState } from '../index';

export interface ILocation {
  id: number;
  name: string;
  fullName?: string;
  regionName?: string;
  nameLocative?: string;
  nameGenitive?: string;
  route: string;
  alias: string;
  localityType: string;
  shortLocationType: string;
  latitude: number;
  longitude: number;
}

export interface IState {
  centers: ILocation[];
  currentLocation: ILocation;
}

const initialState: IState = {
  centers: [],
  currentLocation: {
    id: 83,
    name: 'Москва',
    fullName: 'г Москва',
    nameLocative: 'в Москве',
    nameGenitive: 'Москвы',
    route: '6.83.',
    alias: 'moskva',
    localityType: 'город',
    shortLocationType: 'г.',
    latitude: 55.753676,
    longitude: 37.619899,
  },
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setCurrentLocation: (state, { payload }: PayloadAction<ILocation>) => {
      state.currentLocation = payload;
    },
    setLocations: (state, { payload }: PayloadAction<ILocation[]>) => {
      state.centers = payload;
    },
  },
});

const { actions, reducer } = locationSlice;

export const { setCurrentLocation, setLocations } = actions;

export default reducer;

export const locationsSelector = (state: IGlobalState): IState => state.locations;
export const currentLocationSelector = (state: IGlobalState): IState['currentLocation'] =>
  locationsSelector(state).currentLocation;

export function useCurrentLocation(): IState['currentLocation'] {
  return useSelector<IGlobalState, IState['currentLocation']>(currentLocationSelector);
}

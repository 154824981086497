import getConfig from 'next/config';

import logger from '@sravni/server-utils/lib/logger';
import { createClient } from '@sravni/ugc/lib/client';

const url = getConfig().publicRuntimeConfig.gatewayUrl;

export default createClient(url, {
  sentryErrorTagName: 'home-frontend-gateway',
  logger,
});

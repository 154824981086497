import { IPublicUser } from '@sravni/types/lib/auth';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IState = {
  loggedIn: boolean;
  account?: IPublicUser;
};

const initialState: IState = {
  loggedIn: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<IPublicUser>) => {
      state.loggedIn = true;
      state.account = payload;
    },
    destroyUser: () => {
      return initialState;
    },
  },
});

const { actions, reducer } = userSlice;

export const { setUser, destroyUser } = actions;

export default reducer;

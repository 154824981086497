import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Mortgages } from '../../interfaces/mortgages';

const initialState: Mortgages = [
  { programName: 'Новостройки', rate: null, url: 'https://www.sravni.ru/ipoteka/zhile-na-pervichnom-rynke/' },
  { programName: 'Вторчика', rate: null, url: 'https://www.sravni.ru/ipoteka/zhile-na-vtorichnom-rynke/' },
  { programName: 'Дома и участки', rate: null, url: 'https://www.sravni.ru/ipoteka/na-stroitelstvo-doma/' },
  { programName: 'Страхование ипотеки', rate: null, url: 'https://www.sravni.ru/strahovanie-ipoteki/' },
];

const mortgagesSlice = createSlice({
  name: 'mortgages',
  initialState,
  reducers: {
    setMortgages: (_, action: PayloadAction<Mortgages>) => {
      return action.payload;
    },
  },
});

const { actions, reducer } = mortgagesSlice;

export const { setMortgages } = actions;

export default reducer;

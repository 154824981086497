interface IEvent {
  event: 'adEvent' | 'mainEvent' | 'pageViewGA' | string;
  title?: string;
  path?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: number;
  'gtm.start'?: number;
}

export interface IEventSource {
  event: IEvent['event'];
  eventValue?: IEvent['eventValue'];
  title?: IEvent['title'];
  path?: IEvent['path'];
  eventCategory?: string | string[];
  eventAction?: string | string[];
  eventLabel?: string | string[];
}

interface IAnalyticsWindow extends Window {
  sravniDataLayer: IEvent[];
}

declare const window: IAnalyticsWindow;

type TCookies = {
  get: (key: string) => any;
};

const isServer = typeof window === 'undefined';

const isCookieClass = (cookies: TCookies | Record<string, any>): cookies is TCookies => {
  return typeof cookies.get === 'function';
};

export const getGaIdFromCookies = (cookies: TCookies | Record<string, any>): string => {
  try {
    let _ga;
    // make it more isomorphic
    if (isCookieClass(cookies)) {
      _ga = cookies.get('_ga');
    } else {
      _ga = cookies._ga;
    }

    const [, , part1, part2] = _ga.split('.');

    return `${part1}.${part2}`;
  } catch (_e) {
    return '0000000000.0000000000';
  }
};

const stringifyEventValue = (strings: undefined | string | string[]): string | undefined => {
  return Array.isArray(strings) ? strings.join('|') : strings;
};

// Send data to Data Layer
export const pushGoogleEvent = (event: IEvent) => {
  if (isServer) {
    return;
  }

  if (process.env.NODE_ENV === 'production') {
    // setup initial datalayer before add new event
    window.sravniDataLayer = window.sravniDataLayer || [];
    window.sravniDataLayer.push(event);
  } else {
    // tslint:disable-next-line:no-console
    console.log('pushEvent', event);
  }
};

// Push events
export const pushEvent = (event: IEventSource) => {
  pushGoogleEvent({
    ...event,
    eventAction: stringifyEventValue(event.eventAction),
    eventLabel: stringifyEventValue(event.eventLabel),
    eventCategory: stringifyEventValue(event.eventCategory),
  });
};

export const makeMainGEvent = (event: Partial<IEventSource>): IEventSource => ({
  eventLabel: undefined,
  eventAction: undefined,
  eventValue: undefined,
  eventCategory: undefined,
  ...event,
  event: 'mainEvent',
});

export const makeAdGEvent = (event: Partial<IEventSource>): IEventSource => ({
  eventLabel: undefined,
  eventAction: undefined,
  eventValue: undefined,
  eventCategory: undefined,
  ...event,
  event: 'adEvent',
});

export const itemLabelFactory = (item) => `${item.organization.name}|${item.name}`;

export type LocalAnalyticsPayload = {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: string | number;
};

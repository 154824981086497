import { ActionFromReducersMapObject, CombinedState, Reducer, StateFromReducersMapObject } from 'redux';

import { configureStore } from '@reduxjs/toolkit';

import { reducers } from './reducers';

export type AppStore = ReturnType<typeof initializeStore>;
export type AppDispatch = AppStore['dispatch'];
export type IGlobalState = ReturnType<
  Reducer<CombinedState<StateFromReducersMapObject<typeof reducers>>, ActionFromReducersMapObject<typeof reducers>>
>;

const isDev = process.env.NODE_ENV === 'development';
const isServer = typeof window === 'undefined';
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

export const initializeStore = (initialState?: IGlobalState) => {
  return configureStore({
    devTools: isDev,
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    ...(initialState && { preloadedState: initialState }),
  });
};

export const getOrCreateStore = (initialState?: IGlobalState): AppStore => {
  if (isServer) {
    return initializeStore(initialState);
  }

  const anyWindow = window as any;

  if (!anyWindow[__NEXT_REDUX_STORE__]) {
    anyWindow[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  }

  return anyWindow[__NEXT_REDUX_STORE__];
};

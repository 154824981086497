import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMetadata } from '../../interfaces/metadata';

export type IState = {
  data: IMetadata | Record<string, any>;
};

const initialState: IState = {
  data: {},
};

const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setMetadata: (state, { payload }: PayloadAction<IMetadata>) => {
      state.data = payload;
    },
  },
});

const { actions, reducer } = metadataSlice;

export const { setMetadata } = actions;

export default reducer;

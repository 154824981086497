import { ISiteSettings } from '@sravni/types/lib/sitesettings';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IState = ISiteSettings;

const initialState: IState = {
  header: {
    menu: undefined,
    mobileMenu: undefined,
  },
  footer: {
    menu: undefined,
    socialLinks: undefined,
    support: undefined,
  },
};

const siteSettingsSlice = createSlice({
  name: 'siteSettings',
  initialState,
  reducers: {
    setSettings: (_, action: PayloadAction<ISiteSettings>) => {
      return action.payload;
    },
  },
});

const { actions, reducer } = siteSettingsSlice;

export const { setSettings } = actions;

export default reducer;

import { DecoratedBannersList, TBannersList } from '../interfaces/advertising';

export const decorateBanners = (banners: TBannersList): DecoratedBannersList => {
  const specialProjectBanner = banners?.teaserInPropositions;

  let decoratedBanners = banners as DecoratedBannersList;

  if (specialProjectBanner) {
    decoratedBanners.teaserInPropositions = [];

    for (let i = 1; i <= 4; i++) {
      decoratedBanners.teaserInPropositions.push({
        ...specialProjectBanner,
        params: {
          ...specialProjectBanner.params,
          order: i,
        },
      });
    }
  }

  return decoratedBanners;
};

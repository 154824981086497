import { depositBaseHref } from '../constants/deposits';
import { DepositsTopPeriods } from '../interfaces/deposits';

export const generateDepositHref = (depositPeriod?: DepositsTopPeriods) => {
  const params = new URLSearchParams({ sortProperty: 'rate' });

  let period: string;
  switch (depositPeriod) {
    case DepositsTopPeriods.THREE_MONTHS:
      period = '365';
      params.append('showBestRatesPopup', '0');
      break;
    case DepositsTopPeriods.SIX_MONTHS:
      period = '365';
      params.append('showBestRatesPopup', '1');
      break;
    case DepositsTopPeriods.TWELVE_MONTH:
      period = '365';
      params.append('showBestRatesPopup', '2');
      break;
    case DepositsTopPeriods.THREE_YEARS:
      period = '1081';
      params.append('showBestRatesPopup', 'true');
      break;
  }

  period && params.append('term', period);
  const paramsString = params.toString();

  return `${depositBaseHref}?${paramsString}`;
};

import React from 'react';

import { Banner, BANNER_TYPES } from '@sravni/react-advertisement';

import { useBanners } from '../../hooks/advertising';
import { AB_NAME } from '../../pages/_app';

export const TopBanner = () => {
  const banners = useBanners();

  if (!banners?.top) return null;

  return <Banner abName={AB_NAME} banner={banners?.top} type={BANNER_TYPES.TOP} />;
};
